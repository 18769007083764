var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',[_vm._v(_vm._s(_vm.name))]),_c('v-form',[_c('v-container',[_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h5',[_vm._v("Surprise your customers with the most stunning images!")])])],1),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Outside")])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"px-3 py-2 default",attrs:{"color":"","outlined":""}},[_vm._v("Upload venue outside images")])],1)],1),_c('v-row',{staticClass:"mt-3 mb-0"},[_c('v-col',{staticClass:"p-0 m-0 custom-item-column d-flex"},[_vm._l((_vm.form.gallery.outside.images),function(outsideImage,index){return _c('v-card',{key:index,staticClass:"align-center",style:({
              'background-image': 'url(' + outsideImage + ') !important',
              'background-position': 'center !important',
              'background-repeat': 'no-repeat !important',
              'background-size': 'cover !important',
              'box-shadow': 'none !important',
              'margin-right': '10px',
              'margin-right': '10px'
            }),attrs:{"color":"white","height":"75","width":"75"},on:{"click":function($event){return _vm.removeImage('outside', index)}}},[_c('v-icon',{staticClass:"mdi mdi-close-circle custom-close-button",attrs:{"color":"primary"}})],1)}),_c('v-card',{staticClass:"d-flex align-center custom-upload-file",attrs:{"color":"white","height":"75","width":"75"},on:{"click":function($event){return _vm.$refs.outsideFiles.click()}}},[_c('div',{staticClass:"text-center flex-grow-1 grey--text text-uppercase"},[_c('div',[_c('i',{staticClass:"mdi mdi-plus"})]),_c('div',[_vm._v("Upload")]),_c('input',{ref:"outsideFiles",staticStyle:{"display":"none"},attrs:{"type":"file","multiple":"","accept":"image/*"},on:{"change":_vm.uploadOutsideFiles}})])])],2)],1),(_vm.form.vendor_spaces.length > 0)?_c('v-row',{staticClass:"mt-5"},[_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"cols":"12","md":"8"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Meeting & Event Spaces")])]),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"px-3 py-2 default",attrs:{"color":"","outlined":""}},[_vm._v("Upload meeting & event spaces images")])],1)],1):_vm._e(),_vm._l((_vm.form.vendor_spaces),function(space,spaceIndex){return _c('v-row',{key:'sp' + spaceIndex,staticClass:"my-0"},[_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"cols":"12","md":"12"}},[_c('label',[_vm._v(_vm._s(space.name))])]),_c('v-col',{staticClass:"flex-wrap p-0 m-0 d-flex"},[_vm._l((_vm.form.gallery.spaces[spaceIndex]
              ? _vm.form.gallery.spaces[spaceIndex].images
              : []),function(spaceImage,index){return _c('v-card',{key:index,staticClass:"my-3 align-center custom-bg-style",style:({
              'background-image': 'url(' + spaceImage + ') !important',
              'background-position': 'center !important',
              'background-repeat': 'no-repeat !important',
              'background-size': 'cover !important',
              'box-shadow': 'none !important',
              'margin-right': '10px',
              'margin-right': '10px'
            }),attrs:{"color":"white","height":"75","width":"75"},on:{"click":function($event){return _vm.removeImage('spaces', spaceIndex, index)}}},[_c('v-icon',{staticClass:"mdi mdi-close-circle custom-close-button",attrs:{"color":"primary"}})],1)}),_c('v-card',{staticClass:"my-3 d-flex align-center custom-upload-file",attrs:{"color":"white","height":"75","width":"75"},on:{"click":function($event){_vm.$refs['space' + spaceIndex + 'Files'][0].click()}}},[_c('div',{staticClass:"text-center flex-grow-1 grey--text text-uppercase"},[_c('div',[_c('i',{staticClass:"mdi mdi-plus"})]),_c('div',[_vm._v("Upload")]),_c('input',{ref:'space' + spaceIndex + 'Files',refInFor:true,staticStyle:{"display":"none"},attrs:{"type":"file","multiple":"","accept":"image/*"},on:{"change":function($event){return _vm.uploadSpaceFiles($event, spaceIndex)}}})])])],2)],1)})],2)],1),_c('v-btn',{staticClass:"float-right mt-5 mr-3",attrs:{"color":"primary"},on:{"click":this.continue}},[_vm._v("Continue")]),((_vm.getVendor && _vm.getVendor.status !== 'active') || _vm.getIsVendorUpdate === false)?_c('v-btn',{staticClass:"float-right mt-5 mr-1",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.submit('draft')}}},[_vm._v("Save As Draft")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }