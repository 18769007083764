<template>
  <div>
    <CoolLightBox
      v-if="images.length > 0"
      :items="images"
      :index="index"
      :fullScreen="true"
      :useZoomBar="true"
      effect="fade"
      @close="index = null"
    >
    </CoolLightBox>

    <div class="gallery-container" :class="{ 'd-flex': !this.isSummary }">
      <div
        class="mx-1 my-1 secondary"
        :class="{
          pointer: images[0],
          'gallery-left-pane': !this.isSummary,
          'gallery-pane-100': this.isSummary,
          default: nonPointerCursor
        }"
        :key="0"
        @click="showImage(0, images)"
        :style="galleryImagesStyle"
      ></div>

      <div class="gallery-pane-100 d-flex mt-3 ml-1" v-if="this.isSummary">
        <div
          v-for="n in [1, 2, 3, 4, 5, 6]"
          class="mr-1 secondary gallery-pane-100-boxes"
          :class="{ pointer: images[n], default: nonPointerCursor }"
          :key="n"
          @click="showImage(n, images)"
          :style="{ backgroundImage: 'url(' + (images[n] ? images[n] : '') + ')' }"
        >
          <h5 v-if="images.length - 7 > 0 && n === 6" class="font-weight-bold gallery-summary-counter-text">
            +{{ images.length - 7 }}
          </h5>
        </div>
      </div>

      <div class="gallery-right-pane" v-else-if="!this.isSummary">
        <div
          class="mx-1 my-1 gallery-top-right secondary"
          :class="{ pointer: images[1] }"
          :key="1"
          @click="showImage(1, images)"
          :style="{ backgroundImage: 'url(' + (images[1] ? images[1] : '') + ')' }"
        ></div>
        <div class="d-flex mx-1 my-1 gallery-top-right-boxes-container">
          <div
            class="mr-1 gallery-bottom-right-boxes secondary"
            :class="{ pointer: images[2] }"
            :key="2"
            @click="showImage(2, images)"
            :style="{ backgroundImage: 'url(' + (images[2] ? images[2] : '') + ')' }"
          ></div>
          <div
            class="gallery-bottom-right-boxes secondary"
            :class="{ pointer: images[3] }"
            :key="3"
            @click="showImage(3, images)"
            :style="{ backgroundImage: 'url(' + (images[3] ? images[3] : '') + ')' }"
          >
            <h5 v-if="images.length - 4 > 0" class="font-weight-bold gallery-counter-text">+{{ images.length - 4 }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
export default {
  name: "Gallery",
  components: { CoolLightBox },
  //props: ['form', 'isSummary'],
  props: {
    form: {
      type: Array / Object,
      default: []
    },
    isSummary: {
      type: Boolean,
      default: false
    },
    nonPointerCursor: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    images() {
      let images = _.map(this.form.gallery, "url");
      if (this.isSummary === true) {
        let rooms = _.values(_.map(this.form.gallery.rooms, "images"));
        let restaurants = _.values(_.map(this.form.gallery.restaurants, "images"));
        let bars = _.values(_.map(this.form.gallery.bars, "images"));
        images = [].concat.apply(
          [],
          _.filter(_.remove(_.map(this.form.gallery, "images"), undefined), image => {
            return image.length > 0;
          })
        );
        _.forEach(rooms, room => {
          _.forEach(room, image => {
            images.push(image);
          });
        });
        _.forEach(restaurants, restaurant => {
          _.forEach(restaurant, image => {
            images.push(image);
          });
        });
        _.forEach(bars, bar => {
          _.forEach(bar, image => {
            images.push(image);
          });
        });
      }
      return _.uniq(images, "id");
    },
    galleryImagesStyle() {
      let image = this.images[0] ? this.images[0] : "";
      return {
        "background-position": "center center",
        "background-size": "cover",
        "background-image": "url(" + image + ")"
      };
    }
  },
  methods: {
    showImage(number, images) {
      if (!this.isSummary) {
        if (images[number] !== undefined) {
          this.index = number;
        }
      }
    }
  },
  data() {
    return {
      index: null
    };
  }
};
</script>
<style scoped>
/* Gallery (START) */
.gallery-container {
  width: 100%;
  height: 300px;
}
.gallery-left-pane {
  width: 70%;
  height: 100%;
  border-radius: 10px;
  background-size: cover;
}
.gallery-pane-100 {
  width: 100%;
  height: 80%;
  border-radius: 10px;
  background-size: cover;
}
.gallery-pane-100-boxes {
  height: 100px;
  width: 20%;
  border-radius: 10px;
  background-size: cover;
}
.gallery-right-pane {
  width: 30%;
}
.gallery-top-right {
  width: 100%;
  height: 50%;
  border-radius: 10px;
  background-size: cover;
}
.gallery-top-right-boxes-container {
  height: 50%;
  width: 100%;
  border-radius: 10px;
}
.gallery-bottom-right-boxes {
  width: 50%;
  height: 97%;
  border-radius: 10px;
  background-size: cover;
}
.gallery-counter-text {
  color: #ffffff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  border-radius: 10px;
  padding-top: 52px;
}

.gallery-summary-counter-text {
  color: #ffffff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  border-radius: 10px;
  padding-top: 35px;
}
.pointer {
  cursor: pointer;
}
.default {
  cursor: default !important;
}
/* Gallery (END) */
</style>
