<template>
  <div>
    <h4>{{ name }}</h4>
    <v-form>
      <v-container>
        <v-row class="mt-3">
          <v-col cols="12" md="8">
            <h5>Surprise your customers with the most stunning images!</h5>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col cols="12" md="8">
            <label class="font-weight-bold">Outside</label>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="" class="px-3 py-2 default" outlined>Upload venue outside images</v-card>
          </v-col>
        </v-row>
        <v-row class="mt-3 mb-0">
          <v-col class="p-0 m-0 custom-item-column d-flex">
            <v-card
              v-for="(outsideImage, index) in form.gallery.outside.images"
              :key="index"
              :style="{
                'background-image': 'url(' + outsideImage + ') !important',
                'background-position': 'center !important',
                'background-repeat': 'no-repeat !important',
                'background-size': 'cover !important',
                'box-shadow': 'none !important',
                'margin-right': '10px',
                'margin-right': '10px'
              }"
              @click="removeImage('outside', index)"
              color="white"
              class="align-center"
              height="75"
              width="75"
            >
              <v-icon color="primary" class="mdi mdi-close-circle custom-close-button"></v-icon>
            </v-card>
            <v-card
              @click="$refs.outsideFiles.click()"
              color="white"
              class="d-flex align-center custom-upload-file"
              height="75"
              width="75"
            >
              <div class="text-center flex-grow-1 grey--text text-uppercase">
                <div><i class="mdi mdi-plus"></i></div>
                <div>Upload</div>
                <input
                  style="display: none;"
                  type="file"
                  multiple
                  ref="outsideFiles"
                  accept="image/*"
                  @change="uploadOutsideFiles"
                />
              </div>
            </v-card>
          </v-col>
        </v-row>

        <!--        <v-row class="mt-5">
          <v-col cols="12" md="8">
            <label class="font-weight-bold">Meeting Rooms</label>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="" class="px-3 py-2 default" outlined>Upload location meeting rooms images</v-card>
          </v-col>
        </v-row>
        <v-row class="mt-3 mb-0">
          <v-col class="p-0 m-0 custom-item-column d-flex">
            <v-card
                v-for="(meetingRoom, index) in form.gallery.meeting_rooms.images"
                :key="'meeting'+index"
                :style="{
                  'background-image': 'url('+meetingRoom+') !important',
                  'background-position': 'center !important',
                  'background-repeat': 'no-repeat !important',
                  'background-size': 'cover !important',
                  'box-shadow': 'none !important',
                  'margin-right': '10px',
                  'margin-right': '10px',
                }"
                @click="removeImage('meeting_rooms', index)"
                color="white"
                class="align-center"
                height="75"
                width="75"
            >
              <v-icon color="primary" class="mdi mdi-close-circle custom-close-button"></v-icon>
            </v-card>

            <v-card
                @click="$refs.meetingRooms.click()"
                color="white"
                class="d-flex align-center custom-upload-file"
                height="75"
                width="75"
            >
              <div
                  class="flex-grow-1 text-center grey&#45;&#45;text text-uppercase"
              >
                <div><i class="mdi mdi-plus"></i></div>
                <div>Upload</div>
                <input style="display: none;" type="file" multiple ref="meetingRooms" accept="image/*" @change="uploadMeetingRoomsFiles" />
              </div>
            </v-card>
          </v-col>

        </v-row>-->

        <!--Meeting & Event Spaces (START)-->
        <v-row class="mt-5" v-if="form.vendor_spaces.length > 0">
          <v-col cols="12" md="8" class="pb-0 mb-0">
            <label class="font-weight-bold">Meeting & Event Spaces</label>
          </v-col>
          <v-col cols="12" md="4" class="py-0 my-0">
            <v-card color="" class="px-3 py-2 default" outlined>Upload meeting & event spaces images</v-card>
          </v-col>
        </v-row>
        <v-row class="my-0" v-for="(space, spaceIndex) in form.vendor_spaces" :key="'sp' + spaceIndex">
          <v-col cols="12" md="12" class="pb-0 mb-0">
            <label>{{ space.name }}</label>
          </v-col>
          <v-col class="flex-wrap p-0 m-0 d-flex">
            <!--Show Images (START)-->
            <v-card
              v-for="(spaceImage, index) in form.gallery.spaces[spaceIndex]
                ? form.gallery.spaces[spaceIndex].images
                : []"
              :key="index"
              :style="{
                'background-image': 'url(' + spaceImage + ') !important',
                'background-position': 'center !important',
                'background-repeat': 'no-repeat !important',
                'background-size': 'cover !important',
                'box-shadow': 'none !important',
                'margin-right': '10px',
                'margin-right': '10px'
              }"
              @click="removeImage('spaces', spaceIndex, index)"
              color="white"
              class="my-3 align-center custom-bg-style"
              height="75"
              width="75"
            >
              <v-icon color="primary" class="mdi mdi-close-circle custom-close-button"></v-icon>
            </v-card>
            <!--Show Images (END)-->

            <!--Add Images (START)-->
            <v-card
              @click="$refs['space' + spaceIndex + 'Files'][0].click()"
              color="white"
              class="my-3 d-flex align-center custom-upload-file"
              height="75"
              width="75"
            >
              <div class="text-center flex-grow-1 grey--text text-uppercase">
                <div><i class="mdi mdi-plus"></i></div>
                <div>Upload</div>
                <input
                  style="display: none;"
                  type="file"
                  multiple
                  :ref="'space' + spaceIndex + 'Files'"
                  accept="image/*"
                  @change="uploadSpaceFiles($event, spaceIndex)"
                />
              </div>
            </v-card>
            <!--Add Images (END)-->
          </v-col>
        </v-row>
        <!--Meeting & Event Spaces (END)-->
      </v-container>
    </v-form>

    <v-btn color="primary" @click="this.continue" class="float-right mt-5 mr-3">Continue</v-btn>
    <v-btn
      color="secondary"
      class="float-right mt-5 mr-1"
      @click="submit('draft')"
      v-if="(getVendor && getVendor.status !== 'active') || getIsVendorUpdate === false"
      >Save As Draft</v-btn
    >
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "Gallery",
  props: ["name", "index", "form"],
  components: {},
  data: () => ({
    outsideImages: [],
    meetingRooms: []
  }),
  computed: {
    ...mapGetters(["getVendor", "getIsVendorUpdate"])
  },
  methods: {
    continue() {
      this.$emit("locationNextStep", this.index);
    },
    async submit(type = "draft") {
      this.$emit("submit", type);
    },
    removeImage(type = "outside", index, subIndex) {
      if (subIndex !== undefined) {
        this.form.gallery[type][index].images = _.filter(this.form.gallery[type][index].images, (image, imgIndex) => {
          if (subIndex !== imgIndex) {
            return image;
          }
        });
      } else {
        this.form.gallery[type].images = _.filter(this.form.gallery[type].images, (outsideImage, imgIndex) => {
          if (index !== imgIndex) {
            return outsideImage;
          }
        });
      }
    },
    /*removeImage(type='outside', index) {
      if ( type === 'outside' ) {
        this.form.gallery.outside.images = _.filter(this.form.gallery.outside.images, (outsideImage, imgIndex) => {
          if ( index !== imgIndex ) { return outsideImage; }
        });
      }
      else if ( type === 'meeting_rooms' ) {
        this.form.gallery.meeting_rooms.images = _.filter(this.form.gallery.meeting_rooms.images, (meetingRoom, imgIndex) => {
          if ( index !== imgIndex ) { return meetingRoom; }
        });
      }
    },*/
    uploadMeetingRoomsFiles(event) {
      this.onFilesPicked(event, "meeting_rooms");
    },
    uploadSpaceFiles(event, spaceIndex) {
      this.onFilesPicked(event, "spaces", spaceIndex);
    },
    uploadOutsideFiles(event) {
      this.onFilesPicked(event, "outside");
    },
    async onFilesPicked(event, type = "outside", index) {
      const files = event.target.files;
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        file = await this.$options.filters.compressFile(file);
        formData.append("files[" + i + "]", file);
      }

      formData.append("type", type);

      this.setLoader(true);
      axios
        .post("/api/v1/locations/upload-gallery-images", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + this.$store.getters.getAuthToken
          }
        })
        .then(response => {
          let urls = response.data.urls;
          if (this.form.gallery[type] === undefined) {
            this.form.gallery[type] = { type: type, images: [] };
          }
          if (type === "logo") {
            this.form.gallery[type].images = [];
          }
          _.forEach(urls, url => {
            if (index !== undefined) {
              this.form.gallery[type][index].images.push(url.replace("public/", "storage/"));
            } else {
              this.form.gallery[type].images.push(url.replace("public/", "storage/"));
            }
          });
          this.setLoader(false);
        });
    },
    ...mapMutations(["setLoader"])
  }
};
</script>
<style scoped>
.custom-upload-file {
  box-shadow: none !important;
  border: dashed 1px #9e9e9e !important;
}
.custom-item-column {
  padding: 7px !important;
  flex-grow: 0 !important;
}
.custom-close-button {
  position: absolute;
  right: -10px;
  top: -10px;
}
</style>
